import { Controller } from 'stimulus'; 
export default class extends Controller {
  connect() {}

  showPreview(event) {
    event.preventDefault();
    const shipmentId = event.currentTarget.dataset.shipment_id;
    document.getElementById(`action-button-${shipmentId}`).click();
    this.fetchAndShow(shipmentId);
  }

  fetchAndShow(shipmentId) {
    fetch(`/shipments/${shipmentId}/pickslip_preview`)
      .then(response => response.text())
      .then(function(html) {
        document.getElementById('packslip_content').innerHTML = html;
      })
      .then(() => MicroModal.show('packslip-preview-modal'))
  }
}