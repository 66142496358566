import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fulfillmentCenterId", "serviceProviderId", "deliveryDay"];

  connect() {
    this.restoreSavedValues();
  }

  showModal(event) {
    event.preventDefault();
    MicroModal.show("shipment-filters");
  }

  applyFilter(event) {
    event.preventDefault();

    let params = {
      dd: this.deliveryDayTarget.value,
      sp: this.serviceProviderIdTarget.value,
      fc: this.fulfillmentCenterIdTarget.value,
    };

    this.saveToLocalStorage(params);

    // Remove empty values
    params = Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v != null && v != "")
    );

    const encoded = Object.entries(params)
      .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
      .join("&");

    const newUrl = window.location.href.split("?")[0] + `?${encoded}`;
    window.location.href = newUrl;
  }

  clearFilters(event) {
    event.preventDefault();
    localStorage.setItem("dd", "");
    localStorage.setItem("sp", "");
    localStorage.setItem("fc", "");
    const newUrl = window.location.href.split("?")[0];
    window.location.href = newUrl;
  }

  saveToLocalStorage(data) {
    localStorage.setItem("dd", data.dd);
    localStorage.setItem("sp", data.sp);
    localStorage.setItem("fc", data.fc);
  }

  restoreSavedValues() {
    this.deliveryDayTarget.value = localStorage.getItem("dd");
    this.serviceProviderIdTarget.value = localStorage.getItem("sp");
    this.fulfillmentCenterIdTarget.value = localStorage.getItem("fc");
  }
}
