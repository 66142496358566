import { Controller } from "stimulus";
export default class extends Controller {
  clickAllSaveButtons() {
    const activeButtons = document.getElementsByClassName(
      "inventory_save_button"
    );

    activeButtons.forEach((element) => {
      if (!element.classList.contains("hidden")) {
        element.click();
      }
    });
  }
}
