import { Controller } from 'stimulus'; 
export default class extends Controller {
  connect() {}

  showDetail(event) {
    const units = parseInt(event.currentTarget.dataset.units);
    const query = JSON.parse(event.currentTarget.dataset.query);

    if (units > 0 && query) {
      console.log(query)
      this.fetchAndShow(query)  
    }
  }

  fetchAndShow(query) {
    const searchParams = new URLSearchParams(query);
    fetch(`/ops/reports/shipments_detail?${searchParams}`)
      .then(response => response.text())
      .then(function(html) {
        document.getElementById('shipment_detail').innerHTML = html;
      })
      .then(() => MicroModal.show('shipment-detail'))
  }

  gotoShipmentView(event) {
    const shipmentId = event.currentTarget.dataset.shipmentId;
    window.open(`/ops/shipments/${shipmentId}`, '_blank').focus();
  }

  gotoShipmentEdit(event) {
    const shipmentId = event.currentTarget.dataset.shipmentId;
    window.open(`/ops/shipments/${shipmentId}/edit`, '_blank').focus();
  }
}