import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["fulfillmentCenterId", "skuSelection"];
  static values = {
    id: Number,
    inventoryId: Number,
  };
  connect() {
    if (this.idValue) {
      this.fetchAndShow(this.idValue);
    }
  }

  updateSkus(event) {
    const fulfillmentCenterId = event.target.value;
    this.fetchAndShow(fulfillmentCenterId);
  }

  fetchAndShow(fulfillmentCenterId) {
    fetch(
      `/ops/product_issues/sku_options?fc=${fulfillmentCenterId}&product_issue_id=${this.idValue}`
    )
      .then((response) => response.json())
      .then((json) => {
        const selectOptions = json.content;
        this.skuSelectionTarget.innerHTML = selectOptions;
      });
  }
}
