import { Controller } from 'stimulus'; 
export default class extends Controller {
  static values = { shipmentId: String }
  static targets = [ "submitButton" ]

  connect() {}

  reasonChanged(event) {
    // 0 index is 'Select Reason'
    if (event.target.selectedIndex === 0) {
      this.submitButtonTarget.disabled = true;
    } else {
      this.submitButtonTarget.disabled = false;
    }
  }

  showModalView(event) {
    event.preventDefault();
    const shipment_id = event.currentTarget.dataset.shipment_id;
    this.fetchAndShow(shipment_id);
  }

  fetchAndShow(shipment_id) {
    fetch(`/ops/status_changes/new?id=${shipment_id}&status_to=${"batched"}`)
      .then(response => response.text())
      .then(function(html) {
        document.getElementById('change-form-content').innerHTML = html;
      })
      .then(() => {
        this.closeMenuModal(shipment_id);
        MicroModal.show('status-change-modal');
      })
  }  

  closeMenuModal(shipment_id) {
    document.getElementById(`action-button-${shipment_id}`).click();
  }

  createSuccess(response) {
    console.log(response);
    const shipmentId = this.shipmentIdValue;
    MicroModal.close('status-change-modal');
    document.getElementById(`shipment-row-${shipmentId}`).style.display = 'none';
  }
}