import { Controller } from "stimulus";
export default class extends Controller {
  connect() {}

  clearShipmentFilters(event) {
    localStorage.setItem("dd", "");
    localStorage.setItem("sp", "");
    localStorage.setItem("fc", "");
  }
}
