import { Controller } from "stimulus";
export default class extends Controller {
  static values = { order_id: Number, mark_paid_url: String, csrf: String };
  static targets = ["invoiceStatus", "markInvoicePaidButton"];

  connect() {}

  makeInvoicePaid(event) {
    event.preventDefault();
    fetch(this.markPaidUrlValue, {
      headers: {
        "X-CSRF-Token": this.csrfValue,
        "Content-Type": "application/json",
      },
      method: "PUT",
    }).then((response) => {
      this.updateUI();
    });
  }

  updateUI() {
    this.invoiceStatusTarget.innerHTML = "Paid";
    this.markInvoicePaidButtonTarget.style.display = "none";
  }
}
