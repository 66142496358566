import { Controller } from "stimulus";
import { FetchRequest, patch } from "@rails/request.js";
export default class extends Controller {
  static values = {
    id: Number,
    boxQuantity: Number,
    patchUrl: String,
    skuKey: String,
    fullCount: Number,
    partialCount: Number,
    damagedCount: Number,
    totalCount: Number,
  };
  static targets = [
    "fullCount",
    "partialCount",
    "poFullCount",
    "damagedCount",
    "totalCount",
    "saveButton",
    "cancelButton",
    "savedButton",
    "inventoryCountInfo",
  ];

  connect() {
    this.fullCount = parseInt(this.fullCountValue) || 0;
    this.partialCount = parseInt(this.partialCountValue) || 0;

    this.damagedCount = parseInt(this.damagedCountValue) || 0;
    this.totalCount = parseInt(this.totalCountValue) || 0;
    this.boxCount = parseInt(this.boxQuantityValue) || 1;

    this.ownTotalCount = 0;
    this.calculateTotal();
  }

  enableSaveButton() {
    this.savedButtonTarget.classList.add("hidden");
    this.saveButtonTarget.classList.remove("hidden");
    this.cancelButtonTarget.classList.remove("hidden");
  }

  calculateTotal() {
    const full = parseInt(this.fullCountTarget.value) || 0;
    const partial = parseInt(this.partialCountTarget.value) || 0;

    const damaged = parseInt(this.damagedCountTarget.value) || 0;
    const boxCount = parseInt(this.boxQuantityValue) || 1;

    this.ownTotalCount = full * boxCount + partial + damaged;
  }

  updateTotal() {
    this.calculateTotal();
    const displayTotal = this.ownTotalCount;
    this.totalCountTarget.innerText = displayTotal;
    this.enableSaveButton();
  }

  cancel(event) {
    this.fullCountTarget.value = this.fullCount;
    this.partialCountTarget.value = this.partialCount;

    this.damagedCountTarget.value = this.damagedCount;
    this.totalCountTarget.innerText = this.totalCount;

    event.preventDefault();
    this.hideActionButtons();
  }

  hideActionButtons() {
    this.saveButtonTarget.classList.add("hidden");
    this.cancelButtonTarget.classList.add("hidden");
  }

  async update() {
    const data = {
      full_count: this.fullCountTarget.value,
      partial_count: this.partialCountTarget.value,

      damaged_count: this.damagedCountTarget.value,
    };

    const requestUrl = `/ops/inventories/inventory_counts/${this.idValue}`;

    const request = new FetchRequest("patch", requestUrl, {
      body: JSON.stringify({ inventory_count: data }),
    });
    const response = await request.perform();

    if (response.ok) {
      const countData = await response.json;
      this.applySavedData(countData);
      this.hideActionButtons();
      this.savedButtonTarget.classList.remove("hidden");
    } else {
      console.log("Savng Error");
    }
  }

  applySavedData(data) {
    this.fullCount = data.full_count;
    this.partialCount = data.partial_count;

    this.damagedCount = data.damaged_count;
    this.totalCount = data.total_count;
  }
}
