import { Controller } from "stimulus";
import { FetchRequest, patch } from "@rails/request.js";
export default class extends Controller {
  static values = {
    id: Number,
    boxQuantity: Number,
    patchUrl: String,
    skuKey: String,
    poFullCount: Number,
    poPartialCount: Number,
    totalCount: Number,
  };
  static targets = [
    "poFullCount",
    "poPartialCount",
    "totalCount",
    "saveButton",
    "cancelButton",
    "savedButton",
    "inventoryCountInfo",
  ];

  connect() {
    this.poFullCount = parseInt(this.poFullCount) || 0;
    this.poPartialCount = parseInt(this.poPartialCount) || 0;

    this.totalCount = parseInt(this.totalCountValue) || 0;
    this.boxCount = parseInt(this.boxQuantityValue) || 1;

    this.ownTotalCount = 0;
    this.calculateTotal();
  }

  enableSaveButton() {
    this.savedButtonTarget.classList.add("hidden");
    this.saveButtonTarget.classList.remove("hidden");
    this.cancelButtonTarget.classList.remove("hidden");
  }

  calculateTotal() {
    const poFull = parseInt(this.poFullCountTarget.value) || 0;
    const poPartial = parseInt(this.poPartialCountTarget.value) || 0;
    const boxCount = parseInt(this.boxQuantityValue) || 1;

    this.ownTotalCount = poFull * boxCount + poPartial;
  }

  updateTotal() {
    this.calculateTotal();
    const displayTotal = this.ownTotalCount;
    this.totalCountTarget.innerText = displayTotal;
    this.enableSaveButton();
  }

  cancel(event) {
    this.poFullCountTarget.value = this.poFullCount;
    this.poPartialCountTarget.value = this.poPartialCount;

    this.totalCountTarget.innerText = this.totalCount;

    event.preventDefault();
    this.hideActionButtons();
  }

  hideActionButtons() {
    this.saveButtonTarget.classList.add("hidden");
    this.cancelButtonTarget.classList.add("hidden");
  }

  async update() {
    const data = {
      po_full_count: this.poFullCountTarget.value,
      po_partial_count: this.poPartialCountTarget.value,
    };

    const requestUrl = `/ops/inventories/inventory_counts/${this.idValue}`;

    const request = new FetchRequest("patch", requestUrl, {
      body: JSON.stringify({ inventory_count: data }),
    });
    const response = await request.perform();

    if (response.ok) {
      const countData = await response.json;
      this.applySavedData(countData);
      this.hideActionButtons();
      this.savedButtonTarget.classList.remove("hidden");
    } else {
      console.log("Savng Error");
    }
  }

  applySavedData(data) {
    this.poFullCount = data.po_full_count;
    this.poPartialCount = data.po_partial_count;
    this.totalCount = data.total_count;
  }
}
