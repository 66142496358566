import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["submitButton"];

  showModalView(event) {
    event.preventDefault();
    const shipment_id = event.currentTarget.dataset.shipment_id;
    this.fetchAndShow(shipment_id);
  }

  fetchAndShow(shipment_id) {
    fetch(`/ops/shipments/${shipment_id}/shipment_issues/new`)
      .then((response) => response.text())
      .then(function (html) {
        document.getElementById("form-content").innerHTML = html;
      })
      .then(() => {
        this.closeMenuModal(shipment_id);
        MicroModal.show("shipment-issues-modal");
      });
  }

  closeMenuModal(shipment_id) {
    document.getElementById(`action-button-qa`).click();
  }

  onPostSuccess(response) {
    MicroModal.close("shipment-issues-modal");
    window.location.reload();
  }

  onPostError(response) {
    console.log("got error response");
  }
}
