// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "core-js/stable";
import "regenerator-runtime/runtime";

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "stylesheets/application";
import "jquery";
import mapboxgl from "mapbox-gl";
// import mapboxSdk from '@mapbox/mapbox-sdk/services/geocoding';
import "alpinejs";
// import onScan from "onscan.js"
import MicroModal from "micromodal";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css"; // optional for styling
import "controllers";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

MicroModal.init();

// onScan.attachTo(document, {
//   suffixKeyCodes: [9,13], // enter-key expected at the end of a scan
//   reactToPaste: false, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
//   timeBeforeScanTest: 100,
//   avgTimeByChar: 500,
//   minLength: 6,
//   scanButtonLongPressTime: 500,
//   reactToKeyDown: false,
//   singleScanQty: 1,
//   scanButtonKeyCode: false,
//   prefixKeyCodes: [],
//   ignoreIfFocusOn: 'input',
//   stopPropogation: false,
//   preventDefault: false,
//   onScan: function(sCode, iQty) { // Alternative to document.addEventListener('scan')
//       alert('Scanned: ' + iQty + 'x ' + sCode);
//   },
//   onScanError: function(error) { // Alternative to document.addEventListener('scan')
//       console.log(error);
//   },
//   onKeyDetect: function(iKeyCode){ // output all potentially relevant key events - great for debugging!
//       //console.log('Pressed: ' + iKeyCode);
//   }
// });

$(document).on("turbolinks:load", function () {
  tippy("#final-mile-data-missing", {
    content: "Missing Final Mile import data",
  });

  tippy("#address-validation-warning", {
    content: "Address validation not matching",
  });

  tippy("#address-validation-ignored", {
    content: "Address validation ignored",
  });

  tippy("#address-validation-error", {
    content: "Address not valid",
  });

  tippy("#shipment-batches", {
    content: "Shipment Batches",
    placement: "left",
  });

  tippy("#undeliverable-address", {
    content: "Undeliverable address",
    placement: "left",
  });

  tippy("#subscription-comments", {
    content: "Subscription Comments",
    placement: "left",
  });

  tippy("#has-subscription-comments", {
    content: "Shipment has Subscription Comments",
    placement: "top",
  });

  // Mobile Menu
  const mobileBtn = document.querySelector(".mobile-menu-button");
  const sidebar = document.querySelector(".sidebar");

  mobileBtn.addEventListener("click", () => {
    sidebar.classList.toggle("-translate-x-full");
  });

  if ($("#map-container").length > 0) {
    mapboxgl.workerClass =
      require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
    mapboxgl.accessToken =
      "pk.eyJ1Ijoia2Fib2xhYnMiLCJhIjoiY2tqcnFiOW85MHVxejMwdGNmYmR1eWdwdyJ9.gtlceJSFLZL6Sd4WOQ1sog";

    var mississaugaFc = [-79.6467132, 43.6698341];
    var kelownaFc = [-123.1037244, 49.1185563];
    var calgaryFc = [-113.9962045, 51.0611016];

    var map = new mapboxgl.Map({
      container: "map-container",
      style: "mapbox://styles/kabolabs/ckjrrymg83a8b19mqmv7no4ci",
      center: [-92.698082, 51.843232],
      zoom: 3,
    });

    var el = document.createElement("div");
    el.id = "marker";

    var mississaugaFcPopup = new mapboxgl.Popup({ offset: 25 }).setHTML(
      "<strong>Missisauga Fulfillment Center</strong></br></br>1880 Bonhill Rd</br>Mississauga</br>ON</br>L5T1C4"
    );
    var kelownaFcPopup = new mapboxgl.Popup({ offset: 25 }).setHTML(
      "<strong>Pitt Meadows Fulfillment Center</strong></br></br>19418 McNeil Rd</br>Pitt Meadows</br>BC</br>V3Y1Z1"
    );
    var calgaryFcPopup = new mapboxgl.Popup({ offset: 25 }).setHTML(
      "<strong>Calgary Fulfillment Center</strong></br></br>707 Barlow Trail SE</br>Bay E</br>Calgary</br>AB</br>T2E8C2"
    );

    new mapboxgl.Marker({ color: "#239c6d" })
      .setLngLat(mississaugaFc)
      .setPopup(mississaugaFcPopup)
      .addTo(map);
    new mapboxgl.Marker({ color: "#239c6d" })
      .setLngLat(kelownaFc)
      .setPopup(kelownaFcPopup)
      .addTo(map);
    new mapboxgl.Marker({ color: "#239c6d" })
      .setLngLat(calgaryFc)
      .setPopup(calgaryFcPopup)
      .addTo(map);
  }

  // var geocodingClient = mapboxSdk({ accessToken: mapboxgl.accessToken });
  // geocodingClient
  //   .forwardGeocode({
  //     query: 'Mississauga, Ontario, Canada',
  //     autocomplete: false,
  //     limit: 1
  //   })
  //   .send()
  //   .then(function (response) {
  //     if (
  //       response &&
  //       response.body &&
  //       response.body.features &&
  //       response.body.features.length
  //     ) {
  //     var feature = response.body.features[0];

  //     var map = new mapboxgl.Map({
  //       container: 'map-container',
  //       style: 'mapbox://styles/kabolabs/ckjrrymg83a8b19mqmv7no4ci',
  //       center: feature.center,
  //       zoom: 10
  //     });
  //     new mapboxgl.Marker().setLngLat(feature.center).addTo(map);
  //   }
  // });
});

window.Components = {
  customSelect(options) {
    return {
      init() {
        this.optionCount = this.$refs.listbox.children.length;
        this.$watch("selected", (value) => {
          if (!this.open) return;

          if (this.selected === null) {
            this.activeDescendant = "";
            return;
          }

          this.activeDescendant = this.$refs.listbox.children[this.selected].id;
        });
      },
      activeDescendant: null,
      optionCount: null,
      open: false,
      selected: null,
      value: 0,
      choose(option) {
        this.value = option;
        this.open = false;
      },
      onButtonClick() {
        if (this.open) return;
        this.selected = this.value;
        this.open = true;
        this.$nextTick(() => {
          this.$refs.listbox.focus();
          this.$refs.listbox.children[this.selected].scrollIntoView({
            block: "nearest",
          });
        });
      },
      onOptionSelect() {
        if (this.selected !== null) {
          this.value = this.selected;
        }
        this.open = false;
        this.$refs.button.focus();
      },
      onEscape() {
        this.open = false;
        this.$refs.button.focus();
      },
      onArrowUp() {
        this.selected =
          this.selected - 1 < 0 ? this.optionCount - 1 : this.selected - 1;
        this.$refs.listbox.children[this.selected].scrollIntoView({
          block: "nearest",
        });
      },
      onArrowDown() {
        this.selected =
          this.selected + 1 > this.optionCount - 1 ? 1 : this.selected + 1;
        this.$refs.listbox.children[this.selected].scrollIntoView({
          block: "nearest",
        });
      },
      ...options,
    };
  },
  customSelect2(options) {
    let modelName = options.modelName || "selected";

    return {
      init() {
        this.optionCount = this.$refs.listbox.children.length;
        this.$watch("activeIndex", (value) => {
          if (!this.open) return;

          if (this.activeIndex === null) {
            this.activeDescendant = "";
            return;
          }

          this.activeDescendant =
            this.$refs.listbox.children[this.activeIndex].id;
        });
      },
      activeDescendant: null,
      optionCount: null,
      open: false,
      activeIndex: null,
      selectedIndex: 0,
      get active() {
        return this.items[this.activeIndex];
      },
      get [modelName]() {
        return this.items[this.selectedIndex];
      },
      choose(option) {
        this.selectedIndex = option;
        this.open = false;
      },
      onButtonClick() {
        if (this.open) return;
        this.activeIndex = this.selectedIndex;
        this.open = true;
        this.$nextTick(() => {
          this.$refs.listbox.focus();
          this.$refs.listbox.children[this.activeIndex].scrollIntoView({
            block: "nearest",
          });
        });
      },
      onOptionSelect() {
        if (this.activeIndex !== null) {
          this.selectedIndex = this.activeIndex;
        }
        this.open = false;
        this.$refs.button.focus();
      },
      onEscape() {
        this.open = false;
        this.$refs.button.focus();
      },
      onArrowUp() {
        this.activeIndex =
          this.activeIndex - 1 < 0
            ? this.optionCount - 1
            : this.activeIndex - 1;
        this.$refs.listbox.children[this.activeIndex].scrollIntoView({
          block: "nearest",
        });
      },
      onArrowDown() {
        this.activeIndex =
          this.activeIndex + 1 > this.optionCount - 1
            ? 0
            : this.activeIndex + 1;
        this.$refs.listbox.children[this.activeIndex].scrollIntoView({
          block: "nearest",
        });
      },
      ...options,
    };
  },
};
