import { Controller } from "stimulus";
import { FetchRequest, patch } from "@rails/request.js";
export default class extends Controller {
  static values = {
    id: Number,
    boxQuantity: Number,
    patchUrl: String,
    skuKey: String,
    transferToCalgaryCount: Number,
    transferToPittMeadowsCount: Number,
    transferToMississaugaCount: Number,
    damagedCount: Number,
    totalCount: Number,
  };
  static targets = [
    "transferToCalgaryCount",
    "transferToPittMeadowsCount",
    "transferToMississaugaCount",
    "damagedCount",
    "totalCount",
    "saveButton",
    "cancelButton",
    "savedButton",
    "inventoryCountInfo",
  ];

  connect() {
    this.transferToCalgaryCount = parseInt(this.transferToCalgaryCount) || 0;
    this.transferToPittMeadowsCount =
      parseInt(this.transferToPittMeadowsCount) || 0;
    this.transferToMississaugaCount =
      parseInt(this.transferToMississaugaCount) || 0;

    this.damagedCount = parseInt(this.damagedCountValue) || 0;
    this.totalCount = parseInt(this.totalCountValue) || 0;
    this.boxCount = parseInt(this.boxQuantityValue) || 1;

    this.ownTotalCount = 0;
    this.calculateTotal();
  }

  enableSaveButton() {
    this.savedButtonTarget.classList.add("hidden");
    this.saveButtonTarget.classList.remove("hidden");
    this.cancelButtonTarget.classList.remove("hidden");
  }

  calculateTotal() {
    const transferToCalgary =
      parseInt(this.transferToCalgaryCountTarget.value) || 0;
    const transferToPittMeadows =
      parseInt(this.transferToPittMeadowsCountTarget.value) || 0;
    const transferToMississauga =
      parseInt(this.transferToMississaugaCountTarget.value) || 0;

    const boxCount = parseInt(this.boxQuantityValue) || 1;

    this.ownTotalCount =
      transferToCalgary + transferToPittMeadows + transferToMississauga;
  }

  updateTotal() {
    this.calculateTotal();
    const displayTotal = this.ownTotalCount;
    this.totalCountTarget.innerText = displayTotal;
    this.enableSaveButton();
  }

  cancel(event) {
    this.transferToCalgaryCountTarget.value = this.transferToCalgaryCount;
    this.transferToKelownaCountTarget.value = this.transferToKelownaCount;
    this.transferToPittMeadowsCountTarget.value =
      this.transferToPittMeadowsCount;
    this.transferToMississaugaCountTarget.value =
      this.transferToMississaugaCount;

    this.totalCountTarget.innerText = this.totalCount;

    event.preventDefault();
    this.hideActionButtons();
  }

  hideActionButtons() {
    this.saveButtonTarget.classList.add("hidden");
    this.cancelButtonTarget.classList.add("hidden");
  }

  async update() {
    const data = {
      transfer_to_calgary_count: this.transferToCalgaryCountTarget.value,
      transfer_to_mississauga_count:
        this.transferToPittMeadowsCountTarget.value,
      transfer_to_pitt_meadows_count:
        this.transferToMississaugaCountTarget.value,
    };

    const requestUrl = `/ops/inventories/inventory_counts/${this.idValue}`;

    const request = new FetchRequest("patch", requestUrl, {
      body: JSON.stringify({ inventory_count: data }),
    });
    const response = await request.perform();

    if (response.ok) {
      const countData = await response.json;
      this.applySavedData(countData);
      this.hideActionButtons();
      this.savedButtonTarget.classList.remove("hidden");
    } else {
      console.log("Savng Error");
    }
  }

  applySavedData(data) {
    this.transferToCalgaryCount = data.transfer_to_calgary_count;
    this.transferToPittMeadowsCount = data.transfer_to_pitt_meadows_count;
    this.transferToMississaugaCount = data.transfer_to_mississauga_count;

    this.totalCount = data.total_count;
  }
}
