// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["preview", "input", "stored", "icon"];

  readURL() {
    const input = this.inputTarget;
    const output = this.previewTarget;
    const icon = this.iconTarget;

    if (input.files && input.files[0]) {
      let reader = new FileReader();
      reader.onload = function () {
        output.src = reader.result;
      };

      reader.readAsDataURL(input.files[0]);

      this.iconTarget.classList.add("hidden");
      this.previewTarget.classList.remove("hidden");
      // this.previewTarget.classList.add("visible");
    }

    if (this.hasStoredTarget) {
      this.storedTarget.style.display = "none";
    }
  }
}
