import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.displaySearchFilters();
  }

  displaySearchFilters() {
    const dd = localStorage.getItem("dd");
    const sp = localStorage.getItem("sp");
    const fc = localStorage.getItem("fc");

    let filters = [dd, sp, fc].filter(Boolean);
    let display = filters.join(" - ");
  }
}
